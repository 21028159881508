let key = {};

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

let Envname = "demo";
if (Envname == "live") {
  const API_URL = "https://prodapi.vinnex.io"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    frontUrl: "https://www.vinnex.io/",
    toasterOption: toasterOption,
    Vinnexcontract: "0xE12d4bF8b1d8297aE88f3FCA8d19561275383107",
    Tokencontract: "0xC67E20354AaE72F669cdE0a66C37c1C5cc0dd752",
    admin_address: "0x1d2E4F4a55533a3Ce999fb530DC54F0F0d4bbcBe",
    chainid: 56
  };
} else if (Envname == "demo") {
  const API_URL = "https://vinnexapi.wearedev.team"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    frontUrl: "https://vinnex-main-site.pages.dev/",
    toasterOption: toasterOption,
    Vinnexcontract: "0xCDe75c52d63C246232745FbcBCc4954c298085e1",
    Tokencontract: "0x37E70f8a263BB655bB04f04B041225Dfd0fa19Fe",
    admin_address: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    chainid: 97
  };
} else {
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    frontUrl: "http://localhost:3000/",
    toasterOption: toasterOption,
    Vinnexcontract: "0x0D5270f5a4c2fE5A22cB5F80fdBFE8DDf374f57B",
    Tokencontract: "0x37E70f8a263BB655bB04f04B041225Dfd0fa19Fe",
    admin_address: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    chainid: 97
  };
}

export default key;
